import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { I18nService, PlatformHelperService, ImageService } from '@app/core';
import { AccountService } from '@app/core/auth';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { AlertController, NavParams, PopoverController } from '@ionic/angular';
import { RequestInfoService } from '../../services/request-info.service';
import { AuthenticationService } from '@app/core/auth/auth.service';
import { Router } from '@angular/router';
import { LoginFormDialogService } from '@app/shared/login';
import { RequestInformationType } from '@app/models/request-information.model';
import { WhatsAppService } from '../../../core/whatsapp.service';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { CaptchaService } from '@app/spot-buildings/services/captcha.service';
export class RequestInformationComponent {
    constructor(navParams, i18n, popoverCtrl, platformConfigHelper, imageService, alertCtrl, accountSrv, requestInfoSrv, auth, router, loginFormDialog, userActivityService, whatsappService, captchaService) {
        this.navParams = navParams;
        this.i18n = i18n;
        this.popoverCtrl = popoverCtrl;
        this.platformConfigHelper = platformConfigHelper;
        this.imageService = imageService;
        this.alertCtrl = alertCtrl;
        this.accountSrv = accountSrv;
        this.requestInfoSrv = requestInfoSrv;
        this.auth = auth;
        this.router = router;
        this.loginFormDialog = loginFormDialog;
        this.userActivityService = userActivityService;
        this.whatsappService = whatsappService;
        this.captchaService = captchaService;
        this.agents = [];
        this.isMobile = false;
        this.requestInfoType = RequestInformationType.REQ_INFO;
        this.imgCaptcha = '';
        this.errorCaptcha = false;
    }
    ngOnInit() {
        this.generateCaptcha();
        if (window.screen.width < 1000) {
            this.isMobile = true;
        }
        this.auth.$isLoginSubject.subscribe(val => (this.isLoggedIn = val));
        this.customLoginText = this.i18n.get('global.form.defaultSignInMessage');
        this.createForm();
        this.closeIcon = faTimesCircle;
        this.defaultImage = this.platformConfigHelper.Defaults().imagePlaceholder;
        this.input = this.navParams.data.reqInfoInput;
        this.spotTitle = this.input.requestTitle;
        this.noAgentPhotos = !this.input.agents || this.input.agents.length == 0;
        this.agents = this.input.agents && this.input.agents.length > 0 ? [...this.input.agents] : [];
        if (this.agents && this.agents.length > 2) {
            this.agents = this.agents.slice(0, 2);
        }
        if (this.agents && this.agents.length > 0) {
            this.companyLogoImageId = this.agents[0].companyLogoImageId;
            if (!this.companyLogoImageId) {
                this.companyLogoImageId = this.input.companyLogoImageId;
            }
        }
        this.setUserInfo();
        this.newToSiilaTxt = this.i18n.get('requestInformation.newToSiila');
        if (this.input.requestType) {
            this.requestInfoType = this.input.requestType;
        }
        if (this.requestInfoType == RequestInformationType.REQ_INFO) {
            this.userActivityService.trackSpotBuildingMessage(this.input.spotId);
        }
        if (this.requestInfoType == RequestInformationType.REQ_INFO ||
            this.requestInfoType == RequestInformationType.REQ_BROKER) {
            this.sendPlaceHolder = this.i18n.get('requestInformation.pleaseSendInfo');
            this.modalTitle = this.i18n.get('requestInformation.title');
        }
        if (this.requestInfoType == RequestInformationType.REQ_BROKER) {
            this.sendPlaceHolder = this.i18n.get('requestInformation.pleaseSendInfo');
            this.modalTitle = this.i18n.get('requestInformation.title');
        }
        if (this.requestInfoType == RequestInformationType.REQ_TOUR) {
            this.userActivityService.trackSpotBuildingRequestVisit(this.input.spotId);
            this.sendPlaceHolder = this.i18n.get('requestInformation.requestTourSendPlaceHolder');
            this.modalTitle = this.i18n.get('requestInformation.tourTitle');
        }
        if (this.requestInfoType == RequestInformationType.REQ_COMPANY) {
            this.sendPlaceHolder = this.i18n.get('requestInformation.companyPlaceholder');
            this.modalTitle = this.i18n.get('requestInformation.companyTitle');
        }
        if (this.requestInfoType == RequestInformationType.REQ_SCHEDULE) {
            this.sendPlaceHolder = this.i18n.get('requestInformation.schedulePlaceholder');
            this.modalTitle = this.i18n.get('requestInformation.scheduleTitle');
        }
        if (this.requestInfoType == RequestInformationType.REQ_CLAIM) {
            this.sendPlaceHolder = this.i18n.get('requestInformation.claimPlaceholder');
            this.modalTitle = this.i18n.get('requestInformation.claimTitle');
        }
        this.formData.controls['note'].setValue(this.sendPlaceHolder);
    }
    createForm() {
        this.formData = new FormGroup({
            firstName: new FormControl('', Validators.required),
            lastName: new FormControl('', Validators.required),
            phoneNumber: new FormControl(''),
            /* ext: new FormControl('', Validators.required), */
            email: new FormControl('', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$')]),
            note: new FormControl('', Validators.required),
            inputCaptcha: new FormControl('')
        });
        this.noteFormCtrl = this.formData.controls['note'];
    }
    setUserInfo() {
        this.currentUser = this.accountSrv.getCurrentUser;
        if (this.currentUser != null) {
            this.formData.controls['firstName'].setValue(`${this.currentUser.firstName}`);
            this.formData.controls['lastName'].setValue(`${this.currentUser.lastName}`);
            this.formData.controls['email'].setValue(`${this.currentUser.email}`);
            const phones = this.currentUser.phones;
            if (phones != null && phones.length > 0) {
                let phone = phones.find(phone => phone.phoneType === 'PHONE_NUMBER');
                if (phone != null && phone.phoneNumber.length > 0) {
                    this.formData.controls['phoneNumber'].setValue(phone.phoneNumber);
                }
                else {
                    phone = phones.find(phone => phone.phoneType === 'WHATSAPP');
                    if (phone != null) {
                        this.formData.controls['phoneNumber'].setValue(phone.phoneNumber);
                    }
                }
            }
        }
    }
    closeModal() {
        this.popoverCtrl.dismiss();
    }
    getCompanyLogoImg(id) {
        return id ? this.getMediumImg(id) : '../../../assets/placeholder-image.png';
    }
    getMediumImg(id) {
        return this.imageService.mediumThumbnail(id);
    }
    sendMessage() {
        if (this.formData.invalid && this.validateCaptcha()) {
            this.showAlert(this.i18n.get('requestInformation.enterRequiredFields'));
            return;
        }
        else {
            // send contact email
            if ((this.input.sentEmailTo == undefined || this.input.sentEmailTo.length == 0) && this.agents.length > 0) {
                this.input.sentEmailTo = [this.agents[0].userEmail];
            }
            let requestInfoDTO = {
                spotTitle: this.spotTitle,
                firstName: this.formData.value.firstName,
                lastName: this.formData.value.lastName,
                /* ext: this.formData.value.ext, */
                phoneNumber: this.formData.value.phoneNumber,
                comments: this.formData.value.note,
                email: this.formData.value.email,
                sentEmailTo: this.input.sentEmailTo,
                spotId: this.input.spotId,
                brokerRequest: this.input.brokerRequest,
                requestType: this.requestInfoType
            };
            this.requestInfoSrv.sendRequestInfoEmail(requestInfoDTO, this.i18n.getCurrentLanguage()).then((result) => {
                if (result) {
                    this.showAlert(this.i18n.get('requestInformation.sucessMsg'));
                }
            });
        }
    }
    disableSentBtn() {
        const invalid = this.formData &&
            (this.formData.get('email').invalid ||
                this.formData.get('note').invalid ||
                this.formData.get('firstName').invalid ||
                this.formData.get('lastName').invalid ||
                this.validateCaptcha()); /*||
        this.formData.get('phoneNumber').invalid);  ||
        this.formData.get('ext').invalid); */
        return invalid;
    }
    openLoginPopup() {
        if (!this.isLoggedIn) {
            this.openLoginDialog(this.customLoginText)
                .afterClosed()
                .subscribe((resp) => {
                this.formData.enable();
                if (this.isLoggedIn) {
                    this.setUserInfo();
                    return;
                }
                if (!this.isLoggedIn && resp !== 'close') {
                    this.popoverCtrl.dismiss();
                }
            });
        }
    }
    openLoginDialog(text) {
        const currentRoute = this.router.url;
        this.formData.disable();
        return this.loginFormDialog.openDialog({
            url: currentRoute,
            customText: text,
            autoClose: true,
            autoFocus: true,
            restoreFocus: true
        });
    }
    clickToChat(phoneNumber) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const spotURL = encodeURI(this.buildUrl());
            const headerMsg = this.i18n.get('global.broker-detail.clickToChat.whatsAppMsgHeader');
            const msgToUserAgent = this.i18n.get('global.broker-detail.clickToChat.msgToUserAgent');
            const message = `${headerMsg} ${spotURL}

${msgToUserAgent}
    `;
            this.whatsappService.sendMessageViaWhataspp(phoneNumber, message);
            return false;
        });
    }
    buildUrl() {
        return window.location.href.concat(this.i18n.getLanguagePathURL());
    }
    openWhatsapp(event, agent) {
        event.stopImmediatePropagation();
        if (agent.showWhatsapp && agent.phoneNumber) {
            this.clickToChat(agent.phoneNumber);
        }
        agent.showWhatsapp = true;
        return false;
    }
    showAlert(alertMsg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertCtrl
                .create({
                header: this.i18n.get('global.form.sendMsgToAgent.notification'),
                subHeader: alertMsg,
                buttons: [
                    {
                        text: this.i18n.get('global.form.sendMsgToAgent.ok'),
                        handler: () => {
                            if (this.formData.valid) {
                                this.popoverCtrl.dismiss();
                            }
                        }
                    }
                ]
            })
                .then(alert => {
                alert.present();
            });
        });
    }
    generateCaptcha() {
        this.captchaService.generateCaptcha();
        this.imgCaptcha = this.captchaService.imgCaptcha;
        this.textCaptcha = this.captchaService.textCaptcha;
    }
    validateCaptcha() {
        this.errorCaptcha =
            this.formData.controls.inputCaptcha.value != this.textCaptcha || this.formData.controls.inputCaptcha.value == '';
        return this.errorCaptcha;
    }
}
