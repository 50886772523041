import * as tslib_1 from "tslib";
import { captchaImg } from '../util/params-captcha';
export class CaptchaService {
    constructor() {
        this.imgCaptcha = '';
        this.usedIndexes = new Set();
        this.img = captchaImg.images;
    }
    generateCaptcha() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let indexImg = Math.floor(Math.random() * this.img.length);
            while (this.usedIndexes.has(indexImg)) {
                indexImg = Math.floor(Math.random() * this.img.length);
            }
            this.usedIndexes.add(indexImg);
            if (this.usedIndexes.size === this.img.length) {
                this.usedIndexes.clear();
            }
            this.imgCaptcha = this.img[indexImg].img;
            this.textCaptcha = this.img[indexImg].text;
        });
    }
}
