import { Component, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, EventEmitter } from '@angular/core';
import { SpotBuildingSearchRequestType } from '@app/models/spot-buildings/spot-building-search.model';
import { SpotBuildingDetailDTO } from '@app/models/spot-buildings/spot-buildings.model';
import { IonSlides } from '@ionic/angular';
import { SpotCardSliderService } from '../services/spot-card-slider.service';
import { faChevronLeft, faChevronRight, faShareAlt, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import {
  BreakPointsType,
  DirectionType,
  SliderOptionsType,
  sliderStatusType
} from '@app/models/shared/lazy-slider.model';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { FavoriteChange } from '../../models/shared/shared-interfaces.model';
import { HttpHeaders } from '@angular/common/http';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-spot-card-slider',
  templateUrl: './spot-card-slider.html',
  styleUrls: ['./spot-card-slider.scss']
})
export class AppSpotCardSlider implements OnInit, OnChanges {
  @ViewChild('slider', { static: false }) slider: IonSlides;
  @Input() noDataMsg?: string = 'No Data Found';
  @Input() querySize?: number;
  @Input() breakpoints?: BreakPointsType;
  @Input() cardContainerClass?: string = '';
  @Input() hideFavIcon?: boolean;
  @Input() onlyFavorites?: boolean;
  @Input() spotBuildingSearchPayload?: SpotBuildingSearchRequestType = cloneDeep(
    this.spotCardSliderSvc.defaultSearchRequest
  );
  @Output() favoriteChangeEvent = new EventEmitter<FavoriteChange>();
  @Output() totalCountEvent = new EventEmitter<number>();
  @Input() isPromoted?: boolean;

  firstFetch: boolean = true;
  queryPage: number = 1;
  disabledNext: boolean = false;
  disabledPrev: boolean = true;
  allSpotIds: number[] = [];
  spotBuildings: SpotBuildingDetailDTO[] = [];
  loading: boolean = false;
  error: Error;
  leftArrow: IconDefinition;
  rightArrow: IconDefinition;
  activeListingIds: any[] = [];
  changeSlide$: Subject<DirectionType> = new Subject<DirectionType>();
  slideOptionOverride: SliderOptionsType = {
    ...this.spotCardSliderSvc.defaultSliderOpts,
    breakpoints: this.breakpoints || this.spotCardSliderSvc.defaultSliderOpts.breakpoints
  };

  promotedCards: number[] = [1, 2, 3];
  isMobile: boolean;

  constructor(private spotCardSliderSvc: SpotCardSliderService, private breakpointObserver: BreakpointObserver) {
    this.leftArrow = faChevronLeft;
    this.rightArrow = faChevronRight;
    this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  ngOnInit(): void {
    if (!this.isPromoted) {
      this.fetchSpotBuildings();
    }
  }

  compareObjects(previous: any, current: any) {
    return JSON.stringify(previous) === JSON.stringify(current);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const payload = changes.spotBuildingSearchPayload;
    let goToRefresh = false;
    if (payload) {
      const previousPayload = payload.previousValue;
      const currentValue = payload.currentValue;

      if (previousPayload) {
        if (!this.compareObjects(previousPayload, currentValue)) {
          goToRefresh = true;
        }
      }

      if (!this.compareObjects(currentValue, this.spotBuildingSearchPayload)) {
        goToRefresh = true;
      }
    }
    if (goToRefresh) {
      this.refresh();
    }
  }

  private refresh() {
    this.clear();
    if (!this.isPromoted) {
      this.fetchSpotBuildings();
    }
  }

  private clear(): void {
    this.allSpotIds = [];
    this.spotBuildings = [];
  }

  async fetchSpotBuildings() {
    try {
      this.loading = true;
      this.spotCardSliderSvc
        .searchAllSpotIdsByCompany(this.spotBuildingSearchPayload)
        .then((results: { headers: HttpHeaders; body: number[] }) => {
          const spotBuildingIds = results.body;
          if (spotBuildingIds.length % 2 == 0) {
            this.querySize = Math.round(spotBuildingIds.length / 2);
            this.querySize = !this.isMobile && this.querySize > 2 ? this.querySize : 3;
          } else {
            this.querySize = Math.ceil(spotBuildingIds.length / 3);
            this.querySize = !this.isMobile && this.querySize > 2 ? this.querySize : 3;
          }
          if (spotBuildingIds) {
            this.spotCardSliderSvc
              .searchSpotsByCompanyAsync(spotBuildingIds, this.queryPage, this.querySize)
              .then((result: SpotBuildingDetailDTO[]) => {
                const spotBuildings = result;
                this.firstFetch = false;
                this.allSpotIds = spotBuildingIds;
                this.updateCounts();
                this.spotBuildings = this.allSpotIds.map(() => null);
                this.concatSpotBuildings(spotBuildings);
                this.loading = false;
              });
          }
        });
    } catch (error) {
      this.loading = false;
      this.firstFetch = false;
      this.handleError(error);
    }
  }

  updateCounts() {
    this.totalCountEvent.emit(this.allSpotIds ? this.allSpotIds.length : 0);
  }

  async fetchMore({ pageSize, pageNumber }: { pageSize: number; pageNumber: number }) {
    try {
      this.loading = true;
      this.queryPage = pageNumber;
      const spotBuildings = await this.spotCardSliderSvc.getSpotBuildingsPaginated(
        this.allSpotIds,
        pageNumber,
        pageSize
      );
      this.concatSpotBuildings(spotBuildings);
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.handleError(error);
    }
  }

  onSlideChange(direction: DirectionType) {
    this.changeSlide$.next(direction);
  }

  concatSpotBuildings(spotBuildings: SpotBuildingDetailDTO[]) {
    if (spotBuildings && spotBuildings.length) {
      let copy = cloneDeep(this.spotBuildings);
      const loadedIndex = (this.queryPage - 1) * this.querySize;
      copy.splice(loadedIndex, spotBuildings.length, ...spotBuildings);
      this.spotBuildings = copy;
    }
  }

  handleError(error: any) {
    this.loading = false;
    this.error = error;
  }

  onSliderUpdate({ isEnd, isBeginning }: sliderStatusType) {
    this.disabledNext = !!isEnd;
    this.disabledPrev = !!isBeginning;
  }

  favoriteChange(changedFavorite: FavoriteChange) {
    if (this.onlyFavorites) {
      this.keepFavoriteSpots(changedFavorite);
      this.favoriteChangeEvent.emit(changedFavorite);
      this.updateCounts();
    }
  }

  keepFavoriteSpots(updatedFavorite: FavoriteChange) {
    this.spotBuildings = this.spotBuildings.filter(spot => spot && spot.favorite);
    if (updatedFavorite && updatedFavorite.id) {
      this.allSpotIds = this.allSpotIds.filter(spotId => spotId != updatedFavorite.id);
    }
  }
}
